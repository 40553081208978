<template>
  <div class="payment payment-completed">
    <CategoryTitle :category="category" />
    <v-container>
      <Breadcrumb class="hidden-sm-and-down px-0" :items="breadcrumbs" />
    </v-container>
    <div
      class="d-flex align-center flex-column justify-center flex-wrap title-wrap px-2"
    >
      <v-img
        width="72"
        height="72"
        class="mb-7"
        :src="
          paymentFailed
            ? '/payment/ecommerce-ko.svg'
            : '/payment/ecommerce-ok.svg'
        "
      ></v-img>
      <h2 v-if="paymentFailed" class="mb-10 ko text-uppercase text-center">
        {{ $t("paymentCompleted.failure.title") }}
      </h2>
      <h2 v-else class="mb-10 ok text-uppercase">
        {{ $t("paymentCompleted.success.title") }}
      </h2>
    </div>

    <v-card elevation="0" class="card text-center mx-auto py-6">
      <!-- pagamento fallito -->
      <v-card-text v-if="paymentFailed">
        <h3>
          {{ $t("paymentCompleted.failure.messageStart") }}
          <router-link
            style="text-decoration: none;"
            :to="{ name: 'Order', params: { orderId: order.orderId } }"
            >{{ order.orderId }}</router-link
          >
          {{ $t("paymentCompleted.failure.messageEnd") }}
          <br />
          {{ $t("paymentCompleted.failure.retry") }}
        </h3>
      </v-card-text>

      <!-- pagamento completato orderStatusId 3 -->
      <v-card-text v-if="!paymentFailed && order.orderStatusId === 3">
        <!-- deliveryServiceId 1 -->
        <template v-if="order.shippingAddress.deliveryServiceId === 1">
          <h3>
            {{ $t("paymentCompleted.deliveryService.1.title") }}
          </h3>
          <h2>
            <router-link
              style="text-decoration: none;"
              :to="{ name: 'Order', params: { orderId: order.orderId } }"
            >
              {{ order.orderId }}
            </router-link>
          </h2>
          <p class="mt-5">
            {{ $t("paymentCompleted.deliveryService.1.selectedPoint") }}
            <strong>
              {{
                $t(
                  "navbar.address." +
                    order.shippingAddress.addressTypeId +
                    ".format",
                  order.shippingAddress
                )
              }}</strong
            >
          </p>
        </template>
        <!-- deliveryServiceId 2 -->
        <template v-else-if="order.shippingAddress.deliveryServiceId === 2">
          <h3>
            {{ $t("paymentCompleted.deliveryService.2.title") }}
          </h3>
          <h2>
            <router-link
              style="text-decoration: none;"
              :to="{ name: 'Order', params: { orderId: order.orderId } }"
            >
              {{ order.orderId }}
            </router-link>
          </h2>

          <p>
            {{ $t("paymentCompleted.deliveryService.2.selectedPoint") }}
            <strong>
              {{
                $t(
                  "navbar.address." +
                    order.shippingAddress.addressTypeId +
                    ".format",
                  order.shippingAddress
                )
              }}
            </strong>
          </p>
        </template>
      </v-card-text>
    </v-card>
    <PaymentTypeList
      class="payment-methods mt-8 mx-auto"
      mode="order"
      :order-id="order.orderId"
      v-if="showPayment"
    />

    <v-container
      v-if="!paymentFailed"
      justify="center"
      no-gutters
      class="edit-order justify-center d-flex flex-column flex-sm-row"
    >
      <div class="mr-0 mr-sm-6 d-flex align-center justify-center">
        <v-img
          max-width="45"
          max-height="45"
          class="mx-auto mb-7 mb-sm-0"
          src="/payment/edit-list.svg"
        ></v-img>
      </div>
      <div class="text-center text-sm-left">
        <h4 class="text-uppercase mb-3 mb-sm-0">
          {{ $t("paymentCompleted.edit.title") }}
        </h4>
        <i18n path="paymentCompleted.edit.desc" tag="span">
          <a place="orders" href="/profile/orders">{{
            $t("paymentCompleted.edit.link")
          }}</a>
        </i18n>
      </div>
    </v-container>

    <v-container class="delivery-warning text-left mt-7" v-if="!paymentFailed">
      <span>{{ $t("paymentCompleted.deliveryService.warning") }}</span>
    </v-container>

    <!-- pulsanti -->
    <v-container
      class="buttons d-flex justify-center"
      :class="
        $vuetify.breakpoint.xsOnly && paymentFailed ? 'flex-column' : 'flex-row'
      "
    >
      <v-btn outlined color="primary" class="mx-2 mx-sm-10" v-bind:to="'/'">
        {{ $t("paymentCompleted.button.home") }}
      </v-btn>
      <v-spacer v-if="$vuetify.breakpoint.lgAndUp && paymentFailed"></v-spacer>
      <v-btn
        color="primary"
        class="justify-self-md-end my-6 my-sm-0 mx-2 mx-sm-10"
        outlined
        v-if="paymentFailed"
        @click="toggleShowPayment"
      >
        {{ $t("paymentCompleted.button.retry") }}
      </v-btn>

      <v-btn
        v-bind:to="'/profile/orders/' + order.orderId"
        color="primary"
        class="mx-2 mx-sm-10"
        depressed
      >
        {{ $t("paymentCompleted.button.showOrder") }}
      </v-btn>
    </v-container>
  </div>
</template>

<script>
import OrderService from "~/service/orderService";
import Breadcrumb from "@/components/navigation/Breadcrumb";
import categoryMixins from "~/mixins/category";
import CategoryTitle from "@/components/category/CategoryTitle.vue";
import PaymentTypeList from "@/components/payment/PaymentTypeList";
import AnalyticsService from "~/service/analyticsService";
import { mapGetters, mapActions } from "vuex";

export default {
  name: "PaymentCompleted",
  mixins: [categoryMixins],
  components: { CategoryTitle, PaymentTypeList, Breadcrumb },
  data() {
    return { order: {}, showPayment: false };
  },
  computed: {
    ...mapGetters({
      isOrderSentToAnalytics: "app/isOrderSentToAnalytics"
    }),
    breadcrumbs() {
      let breadCrumbs = [];

      breadCrumbs.push({
        to: { name: "Home" },
        text: "Homepage",
        exact: true
      });
      breadCrumbs.push({
        to: {
          name: "PaymentCompleted",
          params: {
            pathMatch: this.category.slug
          }
        },
        text: this.category.name,
        exact: true
      });
      return breadCrumbs;
    },
    paymentFailed() {
      return (
        this.order.orderStatusId == 2 ||
        this.order.orderStatusId == 11 ||
        this.order.orderStatusId == 1
      );
    }
  },
  methods: {
    ...mapActions({
      addOrderSentToAnalytics: "app/addOrderSentToAnalytics"
    }),
    async fetchOrder(orderId) {
      let vm = this;
      vm.order = await OrderService.getOrder(orderId);
      if (vm.order.orderStatusId == 3) {
        if (!vm.isOrderSentToAnalytics(orderId)) {
          try {
            AnalyticsService.purchase(vm.order);
            vm.addOrderSentToAnalytics(orderId);
          } catch (e) {
            console.log(e);
          }
        }
      }
    },
    toggleShowPayment() {
      this.showPayment = !this.showPayment;
    }
  },
  beforeDestroy() {
    global.EventBus.$off("pay-response-ok");
  },
  mounted() {
    let vm = this;
    vm.fetchOrder(this.$route.params.orderId);
    global.EventBus.$on("pay-response-ok", order => {
      vm.showPayment = false;
      vm.fetchOrder(order.orderId);
    });
  }
};
</script>
